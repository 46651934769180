import { createStripeSubscription, getUser } from "@helpers/supabase";
import { redirect } from "react-router-dom";

export default async function createStripeSubscriptionAction({
  request,
}: {
  request: Request;
}) {
  const { couponId, currency, plan, priceId } = await getFormValues(request);

  if (!currency || !priceId) return redirect("/checkout/plans");

  const { data: userData } = await getUser();

  const email = userData?.user?.email;

  if (!email) return redirect("/checkout/plans");

  const { data } = await createStripeSubscription({
    couponId,
    currency,
    email,
    priceId,
  });

  const { clientSecret, subscriptionId } = data as {
    clientSecret: string;
    subscriptionId: string;
  };

  const urlSearchParams = new URLSearchParams(request.url.split("?")[1]);

  urlSearchParams.append("client_secret", clientSecret);
  urlSearchParams.append("plan", plan);
  urlSearchParams.append("subscription_id", subscriptionId);

  return redirect("/checkout/payment?" + urlSearchParams.toString());
}

async function getFormValues(request: Request): Promise<{
  couponId: string;
  currency: string;
  plan: "weekly" | "yearly";
  priceId: string;
}> {
  const formData = await request.formData();

  const { couponId, currency, plan, priceId } = Object.fromEntries(formData);

  return { couponId, currency, plan, priceId } as {
    couponId: string;
    currency: string;
    plan: "weekly" | "yearly";
    priceId: string;
  };
}
