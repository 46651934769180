import { createContext, FC, ReactNode, useState } from "react";

export interface GlobalContextProps {
  guestId: string | undefined;
  progress: number;
  setGuestId: (guestId: string) => void;
  setProgress: (step: number) => void;
}

export const GlobalContext = createContext<GlobalContextProps | undefined>(
  undefined,
);

export const GlobalContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [guestId, setGuestId] = useState<string | undefined>();
  const [progress, setProgress] = useState(0);

  return (
    <GlobalContext.Provider
      value={{
        guestId,
        progress,
        setGuestId,
        setProgress,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
