import CheckoutForm from "@components/CheckoutForm";
import Layout from "@components/Layout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { User } from "@supabase/supabase-js";
import { useTranslation } from "react-i18next";
import { Navigate, useLoaderData, useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string,
);

export default function PaymentPage() {
  const [searchParams] = useSearchParams();
  const user = useLoaderData() as User;

  const clientSecret = searchParams.get("client_secret")!;
  const plan = searchParams.get("plan")! as "weekly" | "yearly";
  const subscriptionId = searchParams.get("subscription_id")!;

  const { t } = useTranslation();

  if (!clientSecret) return <Navigate replace to="/checkout/plans" />;

  return (
    <Layout>
      <div className="flex grow flex-col">
        <div className="shrink-0 space-y-2">
          <p className="font-bold">
            {t("pages.checkout.payment.securePaymentTitle")}
          </p>

          <p>{t("pages.checkout.payment.securePaymentText")}</p>

          <p className="font-bold">
            {t("pages.checkout.payment.moneyBackGuaranteeTitle")}
          </p>

          <p>{t("pages.checkout.payment.moneyBackGuaranteeText")}</p>
        </div>

        <div className="mt-10 grow">
          <Elements
            options={{
              appearance: { theme: "flat" },
              clientSecret,
            }}
            stripe={stripePromise}
          >
            <CheckoutForm
              clientSecret={clientSecret}
              plan={plan}
              subscriptionId={subscriptionId}
              userEmail={user.email}
            />
          </Elements>
        </div>
      </div>
    </Layout>
  );
}
