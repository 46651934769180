import {
  DialogPanel,
  DialogTitle,
  Dialog as HeadlessUiDialog,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RevenueCatSubscription } from "src/types";

interface Props {
  cancelSubscriptions: () => Promise<void>;
  cancelableSubscriptions: RevenueCatSubscription[];
  isSubmitting: boolean;
  onClose: () => void;
  open: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export default function CancelMultipleSubscriptionsDialog({
  cancelSubscriptions,
  cancelableSubscriptions,
  isSubmitting,
  onClose,
  open,
  setIsSubmitting,
}: Props) {
  const { t } = useTranslation();

  const primaryButtonClassName =
    "inline-block border-2 border-black rounded-xl bg-black p-4 text-white w-full hover:opacity-70 disabled:opacity-30";

  const secondaryButtonClassName =
    "inline-block border-2 border-black rounded-xl bg-white p-4 text-black w-full hover:opacity-50 disabled:opacity-30";

  const handleCancelWebSubscriptions = async () => {
    setIsSubmitting(true);
    await cancelSubscriptions();
    setIsSubmitting(false);
  };

  const hasAppleSubscription = cancelableSubscriptions.some((subscription) =>
    ["app_store", "mac_app_store"].includes(subscription.store),
  );

  const hasGoogleSubscription = cancelableSubscriptions.some(
    (subscription) => subscription.store === "play_store",
  );

  return (
    <Transition show={open}>
      <HeadlessUiDialog onClose={onClose} open={open}>
        {/* NOTE: The backdrop, rendered as a fixed sibling to the panel container */}
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div aria-hidden="true" className="fixed inset-0 bg-black/70" />
        </TransitionChild>

        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {/* NOTE: Scrollable dialog, see: https://headlessui.com/react/dialog#scrollable-dialogs */}
          <div className="fixed inset-0 w-screen overflow-y-auto p-4">
            <div className="flex min-h-full items-center justify-center">
              {/* NOTE: The actual dialog panel  */}
              <DialogPanel className="bg-help-center-white max-w-lg space-y-4 rounded-3xl p-8 text-black shadow sm:w-full">
                <DialogTitle as="div" className="text-right">
                  <button
                    className="rounded-full text-[#82899C] hover:opacity-80"
                    onClick={onClose}
                    type="button"
                  >
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </DialogTitle>

                <div className="flex h-full flex-col items-center justify-center space-y-8 text-center">
                  <ExclamationTriangleIcon className="text-help-center-warning h-20" />

                  <div className="space-y-5">
                    <p className="text-2xl font-bold">
                      {t(
                        "pages.helpCenter.cancelation.multipleActiveSubscriptions",
                      )}
                    </p>

                    <p>{t("pages.helpCenter.cancelation.whichOneToCancel")}</p>

                    <button
                      className={primaryButtonClassName}
                      disabled={isSubmitting}
                      onClick={handleCancelWebSubscriptions}
                    >
                      WEB
                    </button>

                    {hasAppleSubscription && (
                      <Link
                        className={primaryButtonClassName}
                        to="/help-center/cancelation/apple"
                      >
                        Apple
                      </Link>
                    )}

                    {hasGoogleSubscription && (
                      <Link
                        className={primaryButtonClassName}
                        to="/help-center/cancelation/google"
                      >
                        Google
                      </Link>
                    )}

                    <button
                      className={secondaryButtonClassName}
                      disabled={isSubmitting}
                      onClick={cancelSubscriptions}
                    >
                      {t("pages.helpCenter.cancelation.all")}
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </TransitionChild>
      </HeadlessUiDialog>
    </Transition>
  );
}
