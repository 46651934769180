import { useEffect } from "react";

const installFacebookPixel = () => {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    // NOTE: Disable async to ensure the Facebook Pixel is loaded before any
    // other scripts, see: https://stackoverflow.com/a/47687279
    // t.async = !0;
    t.async = false;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js",
  );
};

// eslint-disable-next-line react/prop-types
export default function FacebookPixel({ pixelId }) {
  useEffect(() => {
    installFacebookPixel();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.fbq("init", pixelId);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.fbq("track", "PageView");
  }, [pixelId]);

  return null; // Since this component doesn't render anything
}
