import businessPromptsUrl from "@assets/business_prompts.png";
import Button from "@components/Button";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function AiHelperPage() {
  const { t } = useTranslation();

  return (
    <main className="custom-container flex h-dvh flex-col justify-between overflow-y-auto pb-14 pt-8">
      <div className="mx-6 shrink self-center">
        <img
          alt={t("pages.onboarding.short.aiHelper.businessPrompts")}
          className="md:w-80"
          src={businessPromptsUrl}
        />
      </div>

      <h1 className="my-5 shrink-0 text-center text-2xl">
        <Trans
          components={{
            bold: <span className="font-bold" />,
            colored: <span className="theme-text-colored" />,
          }}
          i18nKey="pages.onboarding.short.aiHelper.heading"
        />
      </h1>

      <Form className="shrink-0" method="post">
        <Button title={t("shared.continue")} type="submit" />
      </Form>
    </main>
  );
}
